<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Item</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Item Name *"
                    v-model="Item.itemName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Item Name'"
                    @blur="$v.Item.itemName.$touch()"
                    :is-valid="
                      $v.Item.itemName.$dirty ? !$v.Item.itemName.$error : null
                    "
                  />

                  <div v-if="mode.editMode">
                    <div class="form-group row">
                      <label
                        for="example-text-input text-right"
                        class="col-sm-3 col-form-label"
                      >
                        Check Input Type Decimal
                      </label>
                      <div class="col-sm-9">
                        <select
                          class="form-control"
                          v-model="Item.checkInputTypeDecimal"
                          :disabled="mode.viewMode"
                        >
                          <!-- <option disabled value="">--Select One Option--</option> -->
                          <option value="0">No</option>
                          <option value="1">Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <CInputFile
                    label="Image "
                    horizontal
                    type="file"
                    :disabled="mode.viewMode"
                    accept="image/*"
                    @change="uploadImage"
                  />
                  <!--  :is-valid="$v.task.fileId.$dirty? !$v.task.fileId.$error  : null"
                  @blur="$v.task.fileId.$touch()"
                 invalid-feedback="Please upload the Image"-->
                  <CRow>
                    <CCol md="3"></CCol>
                    <CCol md="9" align="center">
                      <CCard
                        v-if="Item.fileId && !$v.Item.fileId"
                        style="margin-top: 20px"
                      >
                        <CCardBody align="center">
                          <CButton
                            color="danger"
                            style="float: right"
                            @click="removeimage()"
                            :disabled="mode.viewMode"
                          >
                            <CIcon
                              name="cilXCircle"
                              description="Delete"
                            ></CIcon>
                          </CButton>
                          <CImg
                            v-if="Item.fileId"
                            :src="imageUrl(Item.fileId)"
                            height="180px"
                            alt="Responsive image"
                          >
                          </CImg>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveChecklist()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Item",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      taskId: "",
      itemId: "",
      Item: {
        itemId: "0",
        taskId: "0",
        itemName: "",
        fileId: "",
        checkInputTypeDecimal: "0",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    Item: {
      itemName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.taskId = this.$route.params.taskId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.itemId = this.$route.params.itemId;
      this.taskId = this.$route.params.taskId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.itemId = this.$route.params.itemId;
      this.taskId = this.$route.params.taskId;
      this.getUser();
    }
  },
  components: { datetime },
  methods: {
    imageUrl(fileId) {
      console.log(fileId);
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + fileId;
    },

    removeimage() {
      this.Item.fileId = "";
    },

    uploadImage(files, event) {
      const URL = process.env.VUE_APP_API_HOST + "/emapp/file/upload";
      console.log("uploadImage", URL);
      console.log("files", files);
      console.log("event", event);
      let fdata = new FormData();
      fdata.append("file", files[0]);

      // fdata.append("userId","uid");

      fetch(URL, {
        method: "POST",
        body: fdata,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("image upload response > ", data);
          this.Item.fileId = data.data.fileId;
        });
    },

    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "Items",
        params: { taskId: this.$route.params.taskId },
      });
    },

    saveChecklist() {
      this.$v.Item.$touch();
      if (this.$v.Item.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.Item));
      } else {
        // fetch(process.env.VUE_APP_API_HOST + "/emapp/web/save/item/"+this.taskId, {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: JSON.stringify(this.Item),
        // })
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/item/" +
            this.taskId,
          this.Item
        ).then((Item) => {
          console.log(JSON.stringify(Item));

          this.$router.push({
            name: "Items",
            params: { taskId: this.$route.params.taskId },
          });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/item/" +
          this.itemId
      )
        .then((response) => response.json())
        .then((data) => (this.Item = data));
    },
  },
  mounted() {},
};
</script>
